<template>
  <a href="javascript:;" @click="handleClick">
    <slot></slot>
  </a>
</template>

<script>
import Vue from "vue";

export default Vue.component("QLink", {
  props: {
    to: {
      type: String,
      required: true,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    query: {
      type: Object,
    },
    params: {
        type: Object
    }
  },
  methods: {
    handleClick() {
      if (this.enable) {
        this.$router.push({ path: this.to, query: this.query || {}, params: this.params || {} });
      }
    },
  },
});
</script>